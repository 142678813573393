
  import ContextParameter from 'models/context_parameter.coffee'

  import DataTypeEditor from 'components/botbuilder/data_type_editor.vue'
  import { Combobox, Input, Label, Switch } from 'components/generic'

  export default
    props:
      modelValue: ContextParameter

    data: ->
      contextParameter: @modelValue
      categories: []

    computed:
      labelHelpText: ->
        return if !@contextParameter.pristine
        'The context parameter’s key will be generated based on this label.'

    created: ->
      @setCategories()

    methods:
      setCategories: (query) ->
        @categories = Array.unique(
          [query].concat(
            @$root.contextParameters
              .filter (cp) -> !cp.isPredefined
              .map (cp) -> cp.category
              .sort (a, b) -> a.localeCompare(b)
          ).compact()
        )
      filterCategories: (category, query)->
        category.toLowerCase().startsWith(query.toLowerCase())
      update: ->
        @$emit('update:modelValue', @contextParameter)

    components:
      Combobox: Combobox
      DataTypeEditor: DataTypeEditor
      Input: Input
      Label: Label
      Switch: Switch
