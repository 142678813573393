
  import Date from 'patches/date'
  import { Label } from 'components/generic'
  import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
  import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'

  export default
    props:
      settings: Object
      initialSettings: Object
      disabledSettings: Object

    emits: ['change']

    data: ->
      l: l # date formatting
      ranges: [
          id: 'today', name: 'Today'
        ,
          id: 'yesterday', name: 'Yesterday'
        ,
          id: '7days', name: '7D'
        ,
          id: '1month', name: '1M'
        ,
          id: '3months', name: '3M'
        ,
          id: '6months', name: '6M'
        ,
          id: '12months', name: '12M'
        ,
          id: 'thisYear', name: 'this year'
        ,
          id: 'pastYears', name: 'past years'
        ]
      pastYears: [2023..(new Date().getFullYear() - 1)]
      allResolutions: [
        {id: 'hour', name: 'Hour'}
        {id: 'day', name: 'Day'}
        {id: 'week', name: 'Week'}
        {id: 'month', name: 'Month'}
      ]
      selectedResolution: null
      selectedRange: null
      selectedYear: null
      startDate: new Date()
      duration: 0

    computed:
      resolutions: ->
        if @duration == 1
          return [id: 'hour', name: 'Hour']
        resolutions = [id: 'day', name: 'Day']
        if @duration >= 15
          resolutions.push(id: 'week', name: 'Week')
        if @duration >= 89
          resolutions.push(id: 'month', name: 'Month')
        resolutions
      pastYearsSelectorLabel: ->
        return 'past years' if @selectedRange.id != 'pastYears'
        @selectedYear
      endDate: ->
        # taken from https://stackoverflow.com/questions/563406/how-to-add-days-to-date#answer-19691491
        newDate = new Date(@startDate)
        newDate.setDate(newDate.getDate() + @duration - 1);
        newDate

    created: ->
      @selectedResolution = (@allResolutions.find (res) => res.id == (@settings.resolution || 'day')) || @allResolutions[0]
      @selectedRange = @ranges.find((range) => range.id == @initialSettings.range) ||
        @ranges.find((range) => range.id == '1month')
      @selectedYear = @initialSettings.year
      @setRange(@selectedRange)

    watch:
      resolutions: ->
        return unless @selectedResolution?
        return if @resolutions.find (res) => @selectedResolution.id == res.id
        @selectedResolution = @resolutions[0]

    methods:
      setResolution: (resolution) ->
        @selectedResolution = resolution
        @setStartDate()
        @setDuration()
        @emit()
      setRange: (range) ->
        @selectedRange = range
        @selectedYear = null if range.id != 'pastYears'
        @setStartDate()
        @setDuration()
        @$nextTick =>
          @setStartDate()
          @setDuration()
          @emit()
      setPastYear: ->
        @setRange(@ranges.find (range) -> range.id == 'pastYears')
      setDuration: ->
        @duration = switch @selectedRange.id
          when 'today', 'yesterday'
            1
          when 'pastYears'
            isLeap = new Date(@startDate.getFullYear(), 1, 29).getMonth() == 1
            if isLeap then 366 else 365
          else
            Date.differenceInDays(@startDate, new Date()) + 1
      emit: ->
        return unless @selectedResolution?
        @$nextTick => # wait for resolutions watcher
          @$emit('change'
            range: @selectedRange.id
            year: @selectedYear
            startDate: @startDate.toISODate()
            durationInDays: @duration
            resolution: @selectedResolution.id
          )
      setStartDate: ->
        @startDate = new Date()
        today = new Date()
        switch @selectedRange.id
          when 'today' then null
          when 'yesterday' then @startDate.setDate(today.getDate() - 1)
          when '7days' then @startDate.setDate(today.getDate() - 7)
          when '1month'
            @startDate.setMonth(today.getMonth() - 1)
            @startDate = @startDate.startOfMonth()
          when '3months'
            @startDate.setMonth(today.getMonth() - 3)
            @startDate = @startDate.startOfMonth()
          when '6months'
            @startDate.setMonth(today.getMonth() - 6)
            @startDate = @startDate.startOfMonth()
          when '12months'
            @startDate.setMonth(today.getMonth() - 12)
            @startDate = @startDate.startOfMonth()
          when 'thisYear'
            @startDate = today.startOfYear()
          when 'pastYears'
            @startDate = new Date(@selectedYear, 0, 1)
        @adjustStartDate()
      adjustStartDate: ->
        switch @selectedResolution?.id
          when 'month'
            @startDate = @startDate.startOfMonth()
          when 'week'
            @startDate = @startDate.startOfWeek()
      rangeDisabled: (range) ->
        @disabledSettings?.ranges?[range.id]
      resolutionDisabled: (resolution) ->
        @disabledSettings?.resolutions?[@selectedRange.id]?[resolution.id]

    components:
      CheckIcon: CheckIcon,
      Label: Label
      Listbox: Listbox,
      ListboxButton: ListboxButton,
      ListboxOption: ListboxOption,
      ListboxOptions: ListboxOptions,
      SelectorIcon: SelectorIcon,
