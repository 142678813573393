import { marked } from 'marked'
import InboxMessage from 'models/inbox_message'

export default class Conversation
  @ATTRIBUTES = [
    'stageId'
    'channel'
    'lastMessage'
    'lastMessageSource'
    'lastMessageTime'
    'lastMessageReadByAnyAgent'
    'handoverSession'
    'context'
    'notes'
    'statistics'
    'annotationsSuggested'
    'handoverTags'
    'status' # UNKNOWN,ONLINE,IDLE,OFFLINE
    'typing'
    # TODO: check
    'timezone'
    'coBrowsingSessionId'
  ]
  @USER_ATTRIBUTES = [
    'nativeUserId'
    'firstName'
    'lastName'
    'profilePictureURL'
    'timeFirstSeen'
    'timeLastSeen'
  ]

  constructor: (data) ->
    @id = data.user.id
    @messages = []
    @context = {}
    @notes = {}
    @update(data)

  update: (data) ->
    if data.typing
      clearTimeout(@typingTimeout)
      @typingTimeout = setTimeout =>
        @typing = false
      , 20000
    if data.status
      clearTimeout(@statusTimeout)
      @statusTimeout = setTimeout =>
        @status = 'OFFLINE'
      , 20000
    for attribute in @constructor.ATTRIBUTES
      Vue.set(@, attribute, data[attribute]) if data[attribute]?
    for attribute in @constructor.USER_ATTRIBUTES
      Vue.set(@, attribute, data.user[attribute]) if data.user?[attribute]?
    return this

  load: (api) ->
    api.loadConversation(@).then (data) => @update(data)

  loadLastMessage: (api) ->
    api.loadMessages(@, pageSize: 1).then (data) => @addMessage(data[0])

  saveNotes: (api) ->
    api.saveConversationNotes(@, @notes.text)

  addMessage: (data) ->
    existingMessage = @messages.find (message) ->
      (data.id? && message.id == data.id) || (data.messageId? && message.id == data.messageId)
    if message = existingMessage
      existingMessage.update(data)
    else
      message = new InboxMessage(
        Object.assign({id: data.messageId}, data)
      )
      predecessorIndex = @messages.findLastIndex (m) -> m.timestamp <= message.timestamp
      @messages.splice(predecessorIndex + 1, 0, message)
    lastMessage = @messages.last
    @lastMessage = lastMessage.message
    @lastMessageSource = lastMessage.origin
    @lastMessageTime = lastMessage.timestamp
    @lastMessageReadByAnyAgent = false
    if @statistics?
      if lastMessage.origin == 'USER'
        @statistics.messagesInbound += 1
      else
        @statistics.messagesOutbound += 1
    return message

  Object.defineProperties @prototype,
    isOfToday:
      get: ->
        moment(@lastMessageTime).isSame(moment(), 'day')
    isOfYesterday:
      get: ->
        moment(@lastMessageTime).isSame(moment().subtract(1, 'day'), 'day')
    isOfThisWeek:
      get: ->
        !@isOfToday && !@isOfYesterday &&
          moment(@lastMessageTime).isSame(moment(), 'isoWeek')
    isBeforeThisWeek:
      get: ->
        !@isOfYesterday &&
          moment(@lastMessageTime).isBefore(moment(), 'isoWeek')
    isInHandoverMode:
      get: ->
        @handoverSession? && !@handoverSession.timeClosed
    lastMessageTimeFormatted:
      get: ->
        l(@lastMessageTime, 'dateTime')
    lastMessageTeaser:
      get: ->
        switch @lastMessageSource
          when 'BOT'
            '🤖&emsp;' + @lastMessageText
          when 'USER'
            '👩🏼&emsp;' + @lastMessageText
          when 'HANDOVER'
            '👨🏽‍💻&emsp;' + @lastMessageText
          when 'SYSTEM'
            '⚙&emsp;' + @lastMessageText
    lastMessageText:
      get: ->
        return '' if !@lastMessage
        valuesOf = (message, key) ->
          results = []
          results.push(message[key]) if typeof message[key] == 'string'
          for value in Object.values(message)
            if value instanceof Array
              for item in value
                results = results.concat(valuesOf(item, key)) if item instanceof Object
            else if value instanceof Object
              results = results.concat(valuesOf(value, key))
          results
        rawText = valuesOf(@lastMessage, 'text')[0] || valuesOf(@lastMessage, 'content')[0]
        if rawText then $(marked(rawText)).text() else '' # strip off markdown
    initials:
      get: ->
        ((@firstName?[0] || '') + (@lastName?[0] || '')).toUpperCase()
    fullName:
      get: ->
        "#{@firstName || ''} #{@lastName || ''}"
    channelType:
      get: ->
        @channel?.type || ''
    timeFirstSeenFormatted:
      get: ->
        l(@timeFirstSeen, 'dateTime')
    timeLastSeenFormatted:
      get: ->
        l(@timeLastSeen, 'dateTime')
