
  import { Alert } from 'components/generic'

  export default
    props:
      message: String
      prompt: String
      advancedPrompt: String
      resourceType:
        type: String
        default: 'Project'

    computed:
      promptHtml: ->
        if @advancedPrompt
          @advancedPrompt
            .replaceAll('<a>', "<a href='#{@subscriptionUrl}'>")
            .replaceAll(/<a(.+)>/g, "<a$1 class='text-MercuryBlue-500 hover:text-MercuryBlue-700'>")
        else if @prompt
          "<a href='#{@subscriptionUrl}' class='text-MercuryBlue-500 hover:text-MercuryBlue-700'>#{@prompt}</a>"

    data: ->
      mayEditSubscription: if @resourceType == 'Project'
        Globals.mayEditSubscriptionForProject
      else
        Globals.mayEditSubscriptionForOrganization
      subscriptionUrl: Routes.organization_path(Globals.currentUser.organizationId) + '/subscription'

    components:
      Alert: Alert
