import { createApp } from 'vue_shims'
import GuidGenerator from 'models/guid_generator.coffee'
import WebChannel from 'models/web_channel.coffee'
import { alert } from 'helpers'

import logoUrl from 'images/logo_MercuryAI_bg_black.svg'
import { Button, H3 } from 'components/generic'
import { ClipboardIcon } from '@heroicons/vue/outline'

initWidgetTestApp = (element) ->
  createApp(
    data: ->
      elementData = JSON.parse(element.getAttribute('data'))

      channel: new WebChannel(nativeId: elementData.channel_key)
      userId: GuidGenerator.newGuid()
      backgroundStyles: {}

    created: ->
      fetch("https://cdn.mercury.ai/channels/widget/#{@channel.nativeId}/config.json")
        .then (response) => response.json()
        .then (config) =>
          @backgroundStyles = if config.wallpaperUrl
            backgroundImage: "url(#{config.wallpaperUrl})"
            backgroundSize: 'cover'
          else
            backgroundImage: "url(#{logoUrl})"
            backgroundSize: '50%'
            backgroundPosition: 'top left'
            margin: '5vw'

    mounted: ->
      window.Mercury.initChatWidget(
        @$refs.widgetHolder
        {apiKey: @channel.nativeId, apiServer: @channel.widgetServer}
        {userId: @userId}
      )

    methods:
      copyUrl: (e) ->
        navigator.clipboard.writeText(window.location.href.split('?')[0])
          .then =>
            e.target.blur()
            await alert('Copied URL to clipboard')

    components:
      Button: Button
      ClipboardIcon: ClipboardIcon
      H3: H3

    template: '
      <div v-if="channel" v-html="channel.styleLinkTag"></div>

      <div
        class="h-screen flex flex-col justify-between bg-no-repeat bg-center"
        :style="backgroundStyles"
      ></div>

      <div id="mercury-widget" ref="widgetHolder"></div>
    '
  ).mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('widget-demo-app')
      initWidgetTestApp(element)
