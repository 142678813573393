
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import DialogAction from 'models/dialog_action.coffee'
  import Operation from 'models/operation.coffee'
  import BranchOption from 'models/branch_option.coffee'

  import BranchEditor from './branch_editor.vue'
  import Condition from 'models/condition.coffee'
  import Draggable from 'vuedraggable'
  import HintList from './hint_list.vue'
  import OperationEditor from './operation_editor.vue'
  import { Button, H3, KeyListbox, Label, Tabs } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      action: DialogAction
      hints: Array

    emits: ['change']

    data: ->
      operationTypesForSelect = [label: 'Select'].concat(
        Object.entries(Operation.TYPES)
          .filter ([key, config]) -> key != 'InvokeWorkflow'
          .map ([key, config]) -> {key: key, label: config.label}
      )
      @$root.workflows.forEach (workflow) =>
        return if workflow.triggerType != 'Bot'
        operationTypesForSelect.push(key: "workflow:#{workflow.id}", label: "Workflow: #{workflow.label}")

      operationTypesForSelect: operationTypesForSelect
      selectedOperationType: operationTypesForSelect[0]?.key
      branchEditorRefs: []
      operationEditorRefs: []
      showOperationList: true
      showBranchList: true
      debounceTimeout: null
      currentTab: 'general'
      PlusIcon: PlusIcon

    computed:
      tabs: ->
        [
          { id: 'general', name: 'General' }
          { id: 'hints', name: 'Hints & errors', badge: @hints.length }
        ]

    beforeUpdate: ->
      @branchEditorRefs = []
      @operationEditorRefs = []

    methods:
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(
          => @action.save().then => @$emit('change')
        , 1000
        )
      addBranch: ->
        @action.branches.push(new BranchOption(@action, conditions: [new Condition().export]))
        @$nextTick => @branchEditorRefs[-1..][0].edit()
      removeBranch: (index) ->
        @showBranchList = false
        @$nextTick =>
          @action.branches.splice(index, 1)
          @update()
          @showBranchList = true
      addOperation: ->
        if @selectedOperationType.split(':')[0] == 'workflow'
          workflowId = @selectedOperationType.split(':')[1]
          BotbuilderApi.getWorkflow(workflowId)
            .then (workflow) =>
              @action.operations.push(
                new Operation(
                  @action,
                  type: 'InvokeWorkflow',
                  workflow: workflow
                )
              )
              @update()
              @$nextTick => @operationEditorRefs[-1..][0].edit()
        else
          @action.operations.push(new Operation(@action, type: @selectedOperationType))
          if Object.keys(Operation.TYPES[@selectedOperationType].attributes).length == 0
            @update()
          else
            @$nextTick => @operationEditorRefs.last.edit()
      removeOperation: (index) ->
        @showOperationList = false
        @$nextTick =>
          @action.operations.splice(index, 1)
          @update()
          @showOperationList = true
      setBranchEditorRef: (el) ->
        @branchEditorRefs.push(el) if el?
      setOperationEditorRef: (el) ->
        @operationEditorRefs.push(el) if el?

    components:
      BranchEditor: BranchEditor
      Button: Button
      Draggable: Draggable
      H3: H3
      HintList: HintList
      KeyListbox: KeyListbox
      Label: Label
      OperationEditor: OperationEditor
      Tabs: Tabs
