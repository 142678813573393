
  import WebChannel from 'models/web_channel'
  import { railsEnv } from 'rails_data'

  import ImageUpload from '../image_upload.vue'
  import WidgetSnippet from './widget_snippet.vue'
  import { PhotographIcon } from '@heroicons/vue/outline'

  export default
    props:
      channel: WebChannel
      userId: String

    data: ->
      widgetVersion = if railsEnv == 'production' then 'v4' else 'staging'

      wallpaper: null
      PhotographIcon: PhotographIcon
      srcdoc: """
        <!DOCTYPE html>
        <html>
          <head>
            <link href='https://cdn.mercury.ai/widget/#{widgetVersion}/widget.css' rel='stylesheet'>
            <link rel="stylesheet" type="text/css" href="https://cdn.mercury.ai/channels/widget/#{@channel.nativeId}/style.css">
            <script src='https://cdn.mercury.ai/widget/#{widgetVersion}/widget-main.js' id= 'widget-js'><\/script>
          </head>
          <body>
            <div style="width: 900px; height: 900px;">
              <div id="mercury-widget"></div>
            </div>
            <script>
              let config = {
                apiKey: '#{@channel.nativeId}',
                apiServer: '#{@channel.widgetServer}',
                pollingInterval: 3,
              };
              let user = {userId: '#{@userId}'};
              Mercury.initChatWidget(document.getElementById('mercury-widget'), config, user
              )
            <\/script>
          </body>
        </html>
      """

    computed:
      demoPageUrl: ->
        Routes.widget_path(@channel.nativeId)

    mounted: ->
      @$refs.iframeWrapper.scrollTo(9999, 9999)

    methods:
      updateImage: (fileName) ->
        @channel.uploadAsset(fileName, @[fileName])

    components:
      ImageUpload: ImageUpload
      WidgetSnippet: WidgetSnippet
