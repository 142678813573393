
  import Project from 'models/project.coffee'
  import { messageRetentionTimeChoices } from 'rails_data'
  import { alert, confirm } from 'helpers'

  import LanguageSelection from './language_selection.vue'
  import UpgradePrompt from 'components/shared/upgrade_prompt.vue'
  import { Button, H3, Input, KeyListbox, PageHeader, Panel, Textarea } from 'components/generic'

  export default
    props:
      modelValue: Project

    data: ->
      project: @modelValue
      debounceTimeout: null
      globals: Globals
      messageRetentionTimeChoices: messageRetentionTimeChoices
      maySetMessageRetentionTime: Globals.restrictions.custom_retention

    methods:
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(@save, 1000)
      save: ->
        @project.save()
          .then =>
            @globals.project.is_owner = @project.ownerId == Globals.currentUser.id
      destroy: ->
        return unless await confirm("Do you really want to delete project “#{@project.name}”?")
        @project.delete()
          .then =>
            await alert("Project “#{@project.name}” has been deleted.")
            window.location = '/'

    components:
      Button: Button
      H3: H3
      Input: Input
      LanguageSelection: LanguageSelection
      KeyListbox: KeyListbox
      PageHeader: PageHeader
      Panel: Panel
      Textarea: Textarea
      UpgradePrompt: UpgradePrompt
