export default class KnowledgeSourceBaseUrl
  constructor: (@knowledgeSource, data = {}) ->
    data ||= {}
    # set default values
    @baseUrl = ''
    @maxDepth = 5
    @blackPatterns = []
    @whitePatterns = []
    @exhaustiveSearch = true
    @includeDocuments = false
    # set given values
    @update(data)

  update: (data) ->
    return if !data?
    @baseUrl = data.baseUrl if data.baseUrl?
    @maxDepth = data.maxDepth if data.maxDepth?
    @blackPatterns = data.blackPatterns if data.blackPatterns?
    @whitePatterns = data.whitePatterns if data.whitePatterns?
    @exhaustiveSearch = data.exhaustiveSearch if data.exhaustiveSearch?
    @includeDocuments = data.includeDocuments if data.includeDocuments?

  Object.defineProperties @prototype,
    export:
      get: ->
        baseUrl: @baseUrl
        maxDepth: @maxDepth
        blackPatterns: @blackPatterns
        whitePatterns: @whitePatterns
        exhaustiveSearch: @exhaustiveSearch
        includeDocuments: @includeDocuments
