import { createApp } from 'vue_shims'
import ParameterFilter from 'models/parameter_filter.coffee'
import PlatformApi from 'models/platform_api.coffee'
import BotbuilderApi from 'models/botbuilder_api.coffee'

import { Alert, BackendPagination, Button, Dropdown, PageHeader, ParameterFiltering, Table, TableCell, TableHeadCell, TableHeadRow, TableRow } from 'components/generic'
import { DownloadIcon, TableIcon } from '@heroicons/vue/outline'

initUserTableApp = (element) ->
  userSettings = $(element).data('settings') || {}
  fixParameters = $(element).data('fix-parameters')

  createApp(
    data: ->
      globals: Globals
      contextParameters: $(element).data('context-parameters')
      # request url
      listUrl: Routes.list_project_bot_users_path(Globals.projectId, Globals.stageLevel)
      # request params
      limit: 50
      offset: 0
      sortBy: {lastInteractionTime: '-'}
      filters: (userSettings.filters || []).map (filter) -> new ParameterFilter(filter)
      initialized: false
      # display
      fixParameters: fixParameters
      columns: userSettings.columns || fixParameters.map((param) -> param.label)
      columnQuery: ''
      # pagination component
      pagination: null

    computed:
      availableParameters: ->
        @fixParameters.concat(@contextParameters)
          .sort (a, b) ->
            labelA = a.humanizedLabel || a.label
            labelB = b.humanizedLabel || b.label
            return -1 if labelA < labelB
            return 1 if labelA > labelB
            0
      parametersForFiltering: ->
        @contextParameters.sortByString('label')
      filteredParameters: ->
        @availableParameters.filter (param) =>
          (param.humanizedLabel || param.label).toLowerCase().startsWith(@columnQuery.toLowerCase())
      orderedColumns: ->
        @availableParameters.filter (availableParameter) =>
          availableParameter.label in (@columns || [])
      fetchParams: ->
        limit: @limit
        offset: @offset
        sortBy: (direction + parameter for parameter, direction of @sortBy).join(',')
        filters: JSON.stringify(@filters)

    watch:
      columns: ->
        @saveSettings()
      filters: ->
        @saveSettings()
        @fetch(true)

    created: ->
      window.breadcrumbs.enableEnvironmentSelector()

    mounted: ->
      @pagination = @$refs.pagination
      @fetch(true)

    methods:
      openExportUrl: ->
        window.location = Routes.list_project_bot_users_path(
          Globals.projectId,
          Globals.stageLevel,
          Object.assign({}, @fetchParams, {limit: 1000000, format: 'xlsx', columns: @orderedColumns, startId: null})
        )
      saveSettings: ->
        PlatformApi.Webapp.saveProjectSettings(
          Globals.projectId,
          Globals.stageLevel,
          bot_users:
            columns: @columns
            filters: @filters
        )
      fetch: (reload = false) ->
        @$nextTick => # wait for pagination component to have updated params
          @initialized = true
          @pagination.fetch(reload)
      setSortOption: (keyOrLabel) ->
        if @sortBy[keyOrLabel] == '+'
          @sortBy = {"#{keyOrLabel}": '-'}
        else
          @sortBy = {"#{keyOrLabel}": '+'}
        @fetch(true)
      value: (user, keyOrLabel) ->
        switch keyOrLabel
          when 'userId', 'firstName', 'lastName', 'lastInteractionTime'
            user[keyOrLabel]
          else
            if user.properties?[keyOrLabel] == 'true'
              '<span class="fa fa-check-circle"><span>'
            else if user.properties?[keyOrLabel] == 'false'
              '<span class="fa fa-times-circle"><span>'
            else
              user.properties?[keyOrLabel]

    components:
      Alert: Alert
      BackendPagination: BackendPagination
      Button: Button
      Dropdown: Dropdown
      PageHeader: PageHeader
      DownloadIcon: DownloadIcon
      ParameterFiltering: ParameterFiltering
      TableIcon: TableIcon
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow

    template: '
      <div class="bg-white flex">
        <div class="flex-1 sm:px-6 lg:px-8">
          <PageHeader title="User data">
            <template #cta>
              <Button @click="openExportUrl" type="primary">
                <DownloadIcon class="h-5 w-5 -ml-1 mr-2"/>
                Export xlsx
              </Button>
            </template>
          </PageHeader>

          <ParameterFiltering v-model="filters" :parameters="parametersForFiltering" class="mt-6"/>

          <div class="flex flex-col py-10">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full overflow-hidden sm:px-6 lg:px-8 min-h-96">
                <div class="shadow border-b border-gray-200 sm:rounded-lg">
                  <BackendPagination
                    ref="pagination"
                    :url="listUrl"
                    :parameters="fetchParams"
                    @offset-changed="offset = $event"
                    v-slot="{ items }"
                  >
                    <Table>
                      <thead>
                        <TableHeadRow>

                          <TableHeadCell v-for="parameter in orderedColumns">
                            <div @click="setSortOption(parameter.label)" class="flex cursor-pointer">
                              {{ parameter.humanizedLabel || parameter.label }}
                              <div v-show="!sortBy[parameter.label]" class="chevron inactive"></div>
                              <div v-show="sortBy[parameter.label] == \'+\'" class="chevron"></div>
                              <div v-show="sortBy[parameter.label] == \'-\'" class="chevron chevron-up"></div>
                            </div>
                          </TableHeadCell>

                          <TableHeadCell class="flex justify-center">
                            <Dropdown align="right" @opened="$refs.columnInput.focus()" class="w-min font-medium normal-case text-gray-900">
                              <template #button>
                                <TableIcon class="h-5 w-5"/>
                              </template>
                              <div class="w-80">
                                <input
                                  ref="columnInput"
                                  type="text"
                                  v-model="columnQuery"
                                  class="h-12 w-full px-4 border-b border-gray-100 rounded-t-md text-gray-800 placeholder-gray-400 focus:ring-0 outline-none sm:text-sm"
                                  placeholder="Search attributes"
                                />
                                <ul class="max-h-64 overflow-auto py-2">
                                  <li v-for="parameter in filteredParameters" @click.stop="" class="cursor-default select-none px-4 py-2">
                                    <label class="w-full flex m-0">
                                      <span class="w-6 flex items-center">
                                        <input type="checkbox" v-model="columns" :value="parameter.label">
                                      </span>
                                      {{ parameter.humanizedLabel || parameter.label }}
                                    </label>
                                  </li>
                                </ul>
                              </div>
                            </Dropdown>
                          </TableHeadCell>
                        </TableHeadRow>
                      </thead>
                      <tbody :class="[pagination && items.length && pagination.loading ? \'filter brightness-95\' : \'\']">
                        <TableRow v-if="pagination" v-for="user in items" :key="user.userId">
                          <TableCell
                            v-for="parameter in orderedColumns"
                            class="ellipsis"
                            v-html="value(user, parameter.label)"
                          />
                          <TableCell class="text-center">
                          </TableCell>
                        </TableRow>
                      </tbody>
                    </Table>

                    <template v-if="pagination">
                      <div v-if="items.length == 0 && pagination.loading" class="loading"></div>
                      <template v-else>
                        <Alert v-show="pagination.error" class="m-6">{{ pagination.error }}</Alert>
                        <h3 v-show="initialized && !pagination.error && !items.length" class="text-center">No entries found</h3>
                      </template>
                    </template>
                  </BackendPagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    '
  ).mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('user-table-app')
      initUserTableApp(element)
