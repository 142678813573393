
  import KnowledgeSourceBaseUrl from 'models/knowledge_source_base_url'
  import { confirm } from 'helpers'

  import CrawlingEditor from './crawling_editor.vue'
  import { Input, Label } from 'components/generic'
  import { ChevronDownIcon, XIcon } from '@heroicons/vue/outline'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

  export default
    props:
      modelValue: KnowledgeSourceBaseUrl
      deletable: Boolean

    data: ->
      baseUrl: @modelValue

    methods:
      update: ->
        @$emit('update:modelValue', @baseUrl)
      remove: ->
        return unless await confirm("Dou you really want to delete the url “#{@baseUrl.baseUrl}” from this source?")
        @$emit('remove')

    components:
      ChevronDownIcon: ChevronDownIcon
      CrawlingEditor: CrawlingEditor
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      Input: Input
      Label: Label
      XIcon: XIcon
