import GuidGenerator from 'models/guid_generator.coffee'
import Condition from 'models/condition.coffee'
import ContextUpdate from 'models/context_update.coffee'
import Payload from 'models/payload.coffee'
import WorkflowOperationInputBinding from 'models/workflow_operation_input_binding.coffee'

export default class Operation
  @TYPES =
    ContextUpdate:
      label: 'Update context'
      attributes:
        parameterKey: -> ''
        value: -> ''
        operator: -> 'set'
    ContextUpdateByCategory:
      label: 'Update context by category'
      attributes:
        category: -> ''
        operator: -> 'delete'
    EvaluateToEdgeVariable:
      label: 'Evaluate to edge variable'
      attributes:
        edgeVariableKey: -> ''
        value: -> ''
    UpdateLanguage:
      label: 'Update language'
      attributes:
        language: -> Globals?.project?.languages?[0] || ''
        languageVariation: -> 'DEFAULT'
    DeleteUserData:
      label: 'Delete user data'
      attributes: {}
    TriggerInitiative:
      label: 'Trigger context initiative'
      attributes:
        parameterKey: -> ''
    StartHandover:
      label: 'Start handover'
      attributes: {}
    AcquireOptIn:
      label: 'Acquire opt-in'
      attributes: {}
    AcquireConsent:
      label: 'Acquire consent'
      attributes: {}
    TimedTrigger:
      label: 'Timed trigger'
      attributes:
        milliSeconds: -> 0 # number, // millisecs to wait for trigger
        # key: -> ''
        condition: -> new Condition()
        payload: (self) -> new Payload(self.host)
        # mode: -> 'resume' # other possible values: 'reset', 'rerun'
    InvokeWorkflow:
      label: 'Invoke workflow'
      attributes:
        workflowId: -> ''
        inputBindings: -> []
        outputKeys: -> []
    # content-search-related
    ContentSearch:
      label: 'Content: search'
      attributes:
        entitySchemaKey: -> ''
        number: -> '3' # MercuryRawObject
        searchParameters: -> [] # [ ParameterKey ] // with saerchType in #-notation
    ContentMakeSalient :
      label: 'Content: salient'
      attributes:
        entitySchemaKey: -> ''
        value: -> ''
    ContentMoreResults:
      label: 'Content: more'
      attributes:
        entitySchemaKey: -> ''
        number: -> '3' # MercuryRawObject
    ContentClearSearch:
      label: 'Content: clear'
      attributes:
        entitySchemaKey: -> ''

  constructor: (@host, data = {}) ->
    @id = GuidGenerator.newGuid()
    @type = data.type
    Object.entries(@attributes).forEach ([attribute, defaultValueFunction]) =>
      @[attribute] = defaultValueFunction(@)
    if data.workflow?
      @workflowId = data.workflow.id
      @inputBindings = data.workflow.trigger.botInput.map (botInput) ->
        new WorkflowOperationInputBinding(workflowVariableKey: botInput.key)
      @setOutputKeys(data.workflow)
    @update(data)

  update: (data) ->
    Object.entries(@attributes).forEach ([attribute, defaultValue]) =>
      if data[attribute]?
        if attribute == 'inputBindings'
          @inputBindings = data.inputBindings.map (inputBindingData) ->
            new WorkflowOperationInputBinding(inputBindingData)
        else if attribute == 'condition'
          @condition = new Condition(data.condition)
        else if attribute == 'payload'
          @payload = new Payload(@host, data.payload)
        else
          @[attribute] = data[attribute]

  setOutputKeys: (workflow) ->
    workflowKeys = workflow.trigger.botOutput.map (botOutput) -> botOutput.key
    return false if JSON.stringify(workflowKeys) == JSON.stringify(@outputKeys)
    @outputKeys = workflowKeys

  Object.defineProperties @prototype,
    label:
      get: ->
        Operation.TYPES[@type]?.label || @type
    attributes:
      get: ->
        Operation.TYPES[@type]?.attributes || {}
    attributeKeys:
      get: ->
        Object.keys(@attributes)
    isUnary:
      get: ->
        ContextUpdate.isUnaryOperator(@operator)
    seconds: # for TimedTrigger
      get: ->
        Math.round(@milliSeconds / 1000)
      set: (value) ->
        @milliSeconds = value * 1000
    summary:
      get: ->
        fallback = {header: @label}
        return fallback if !@host?
        index = (@host.operations || []).findIndex (op) => op.id == @id
        return fallback if index == -1
        @host.metaInfo?.operationSummaries?[index] || fallback
    export:
      get: ->
        Object.fromEntries(
          [['type', @type]].concat(
            @attributeKeys.map (attribute) =>
              if ['inputBindings'].includes(attribute)
                [attribute, @[attribute].map((object) -> object.export)]
              else if ['condition', 'payload'].includes(attribute)
                [attribute, @[attribute].export]
              else
                [attribute, @[attribute]]
          )
        )
