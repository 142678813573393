import { platformApiBaseUrl } from 'rails_data'

import { alert } from 'helpers'

export default class PlatformApi
  @call: (method, endpoint, {params, body, headers, token} = {}) ->
    params ||= {}
    token ||= Globals.projectToken
    new Promise (resolve, reject) =>
      if !token
        console.error('No platform token for project set.')
        reject()
      queryString = '?' + Object.keys(params)
        .map((k) -> k + '=' + encodeURIComponent(params[k]))
        .join('&')
      fetch(
        platformApiBaseUrl + endpoint + queryString
        method: method.toUpperCase(),
        body: if body? then JSON.stringify(body) else null,
        headers: Object.assign(
          'X-Requested-With': 'XMLHttpRequest'
          'Content-Type': 'application/json'
          'Accept': 'application/json'
          'Authorization': "Bearer #{token}"
        , headers)
      )
        .then (response) ->
          if response.ok
            resolve(response)
          else
            response.json().then(reject)
        .catch(reject)

  @Webapp:
    call: (method, path, body)->
      fetch(
        path,
        method: method.toUpperCase(),
        body: JSON.stringify(body),
        headers:
          'X-Requested-With': 'XMLHttpRequest'
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          'Content-Type': 'application/json'
          'Accept': 'application/json'
      ).then (response) =>
          return response if response.ok
          if response.status == 422 && !@retried # catch InvalidAuthenticityToken
            @retried = true
            @reloadCsrfToken()
              .then => @call(...arguments)
              .then (response) -> {json: -> {success: true, data: response}}
          else
            try
              responseData = await response.json()
            catch
              throw Error(response.statusText)
            Promise.reject(responseData)
        .then (response) ->
          response.json()
        .then (response) ->
          if response.success
            Promise.resolve(response.data)
          else
            Promise.reject(response)
    callMultipart: (method, path, body)->
      fetch(
        path,
        method: method.toUpperCase(),
        body: body,
        headers:
          'X-Requested-With': 'XMLHttpRequest'
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          'Accept': 'application/json'
      ).then (response) ->
          return response if response.ok
          try
            responseData = await response.json()
          catch
            throw Error(response.statusText)
          Promise.reject(responseData)
        .then (response) -> response.json()
        .then (response) ->
          if response.success
            Promise.resolve(response.data)
          else
            Promise.reject(response)
    reloadCsrfToken: =>
      @Webapp.call('get', Routes.csrf_token_path())
        .then (data) ->
          document.querySelector('meta[name="csrf-token"]').setAttribute('content', data.token)
    getUser: (id, {forModel} = {forModel: false}) =>
      @Webapp.call('get', "/users/#{id}?for_frontent_model=#{forModel}")
    saveUser: (user) =>
      data = new FormData()
      Object.keys(user).forEach (key) ->
        data.append("user[#{key}]", if user[key]? then user[key] else '')
      @Webapp.callMultipart('patch', "/users/#{user.id}", data)
    inviteUser: (data) =>
      @Webapp.call('post', Routes.users_path(), data)
    unlockUserPw: (user) =>
      @Webapp.call('patch', Routes.unlock_pw_user_path(user.id))
    unlockUserTfa: (user) =>
      @Webapp.call('patch', Routes.unlock_tfa_user_path(user.id))
    deleteUser: (user) =>
      @Webapp.call('delete', Routes.user_path(user.id))
    createBc2Project: (data) =>
      @Webapp.call('post', Routes.create_bc2_projects_path(), data)
    getProject: (id) =>
      @Webapp.call('get', Routes.project_path(id, Globals.stageLevel))
    updateProject: (project) =>
      @Webapp.call('patch', Routes.project_path(project.id, Globals.stageLevel), project: project.export)
    deleteProject: (project) =>
      @Webapp.call('delete', Routes.project_path(project.id))
        .catch (response) =>
          await alert(response.message)
          Promise.reject(response)
    saveProjectSettings: (projectId, stageLevel, settings) =>
      @Webapp.call('post', Routes.set_settings_project_path(projectId, stageLevel), settings: settings)
    saveProjectSettingsForAllEnvironments: (projectId, settings) =>
      @Webapp.call('post', Routes.set_settings_for_all_environments_project_path(projectId, 'dev'), settings: settings)
    updateProjectUser: (projectId, stageLevel, projectUser) =>
      @Webapp.call('patch', Routes.project_user_path(projectId, stageLevel, projectUser.id), projectUser)
    addProjectUser: (projectId, stageLevel, projectUser) =>
      @Webapp.call('post', Routes.project_users_path(projectId, stageLevel), projectUser)
    removeProjectUser: (projectId, stageLevel, userId) =>
      @Webapp.call('delete', Routes.project_user_path(projectId, stageLevel, userId))
    saveProfile: (user) =>
      data = new FormData()
      Object.keys(user).forEach (key) ->
        data.append("user[#{key}]", user[key])
      @Webapp.callMultipart('patch', '/profile', data)
    savePassword: (data) =>
      @Webapp.call('patch', '/profile/update_password', data)
    saveTfaMethod: (tfa_method) =>
      @Webapp.call('patch', '/profile/update_tfa', tfa_method: tfa_method)
    promptTfaConfirmation: =>
      @Webapp.call('patch', '/profile/prompt_tfa_confirmation')
    confirmTfaMethod: (code) =>
      @Webapp.call('patch', '/profile/confirm_tfa', code: code)
    createBackupCodes: =>
      @Webapp.call('patch', '/profile/create_backup_codes')
    saveOrganization: (organization, options = {}) =>
      @Webapp.call('patch', "/organizations/#{organization.id}", Object.assign(options, organization: organization))
    getBillingData: (organizationId) =>
      @Webapp.call('get', Routes.billing_data_organization_path(organizationId))
    getInvoiceUrl: (organizationId, invoiceId) =>
      @Webapp.call('get', Routes.invoice_url_organization_path(organizationId, invoiceId))
    paymentHostedPage: (organizationId) =>
      @Webapp.call('get', Routes.payment_hosted_page_organization_path(organizationId))
    addCard: (organizationId, token) =>
      @Webapp.call('post', Routes.add_card_organization_path(organizationId, token: token))
    requestInvoicePayment: (organizationId) =>
      @Webapp.call('post', Routes.request_invoice_payment_organization_path(organizationId))
    getCustomer: (organizationId) =>
      @Webapp.call('get', Routes.customer_data_organization_path(organizationId))
    getSubscription: (organizationId) =>
      @Webapp.call('get', Routes.subscription_data_organization_path(organizationId))
    cancelSubscription: (organizationId) =>
      @Webapp.call('patch', Routes.cancel_subscription_organization_path(organizationId))
    reactivateSubscription: (organizationId) =>
      @Webapp.call('patch', Routes.reactivate_subscription_organization_path(organizationId))
    addAddon: (organizationId, addonId) =>
      @Webapp.call('patch', Routes.add_addon_organization_path(organizationId, addonId))
    cancelAddon: (organizationId, addonId) =>
      @Webapp.call('patch', Routes.cancel_addon_organization_path(organizationId, addonId))
    reactivateAddon: (organizationId, addonId) =>
      @Webapp.call('patch', Routes.reactivate_addon_organization_path(organizationId, addonId))
    upgradePlan: (organizationId, planId) =>
      @Webapp.call('patch', Routes.upgrade_plan_organization_path(organizationId, planId))
    contextParameters: (stageId) =>
      @Webapp.call('get', Routes.context_parameters_project_stage_path(Globals.projectId, Globals.stageLevel, stageId))
    contextParametersRead: =>
      @Webapp.call('get', 'context_parameters_read')
    gameNames: =>
      @Webapp.call('get', 'game_names')
    createRole: (role) =>
      @Webapp.call('post', Routes.organization_roles_path(role.organizationId), role: role.export)
    updateRole: (role) =>
      @Webapp.call('patch', Routes.organization_role_path(role.organizationId, role.id), role: role.export)
    deleteRole: (role) =>
      @Webapp.call('delete', Routes.organization_role_path(role.organizationId, role.id))
    get360DialogChannels: (channelIdsString) => # channelIdsString is a comma-separated list, e.g. "nMUDl3CH,qJYJqqCH"
      @Webapp.call('get', Routes.proxy_dialog_360_channels_path(channelIdsString))
    get360DialogCredentials: (channelId) =>
      @Webapp.call('get', Routes.proxy_dialog_360_credentials_path(channelId))
    getWabaTemplates: (phoneNumber) =>
      @Webapp.call('get', Routes.proxy_get_waba_templates_path(phoneNumber))
    createWabaTemplate: (phoneNumber, templateData) =>
      @Webapp.call('post', Routes.proxy_create_waba_template_path(phoneNumber), template: templateData)
    deleteWabaTemplate: (phoneNumber, templateId) =>
      @Webapp.call('delete', Routes.proxy_delete_waba_template_path(phoneNumber, templateId))
    getFacebookPages: (code) =>
      @Webapp.call('get', Routes.proxy_facebook_pages_path(code))
    getFacebookLongLivedToken: (accessToken) =>
      @Webapp.call('get', Routes.proxy_facebook_long_lived_token_path(accessToken))

  @Analytics:
    call: (method, endpoint, {params, body, projectId, token} = {}) =>
      new Promise (resolve, reject) =>
        projectId ||= Globals.projectUuid
        if !projectId?
          console.error('No project id set.')
          reject()
        @call(method, "projects/#{projectId}/analytics/#{endpoint}", params: params, body: body, token: token)
          .then (response) -> response.json()
          .then (response) ->
            if response.success
              resolve(response.data)
            else
              reject(response)
          .catch(reject)
    encoded: (params) =>
      encodedParams = ObjectProcessor.clone(params)
      encodedParams.filters = JSON.stringify(params.filters)
      encodedParams
    getMessagesSummary: (params) =>
      @Analytics.call('get', 'reports/summary/messages', params: @Analytics.encoded(params))
    getMessagesSeries: (params) =>
      @Analytics.call('get', 'reports/series/messages', params: @Analytics.encoded(params))
    getMessagesPerTimeframe: (params) =>
      @Analytics.call('get', 'reports/summary/messagesByDayAndTime', params: @Analytics.encoded(params))
    getMessagesPerChannel: (params) =>
      @Analytics.call('get', 'reports/summary/messagesByChannel', params: @Analytics.encoded(params))
    getUsersSummary: (params) =>
      @Analytics.call('get', 'reports/summary/users', params: @Analytics.encoded(params))
    getUsersSeries: (params) =>
      @Analytics.call('get', 'reports/series/users', params: @Analytics.encoded(params))
    getUsersPerTimeframe: (params) =>
      @Analytics.call('get', 'reports/summary/usersByDayAndTime', params: @Analytics.encoded(params))
    getUsersPerChannel: (params) =>
      @Analytics.call('get', 'reports/summary/usersByChannel', params: @Analytics.encoded(params))
    getHandoversSummary: (params) =>
      @Analytics.call('get', 'reports/summary/handovers', params: @Analytics.encoded(params))
    getHandoversSeries: (params) =>
      @Analytics.call('get', 'reports/series/handovers', params: @Analytics.encoded(params))
    getHandoversPerTimeframe: (params) =>
      @Analytics.call('get', 'reports/summary/handoversByDayAndTime', params: @Analytics.encoded(params))
    getHandoversPerChannel: (params) =>
      @Analytics.call('get', 'reports/summary/handoversByChannel', params: @Analytics.encoded(params))
    getLivechatSessions: (params) =>
      @Analytics.call('get', 'reports/livechat/sessions', params: @Analytics.encoded(params))
    getLivechatTeams: =>
      @Analytics.call('get', 'reports/livechat/teams')
    getIntents: (params, intentLists) =>
      params = Object.assign({}, params, botIntents: intentLists.bot.join(','), userIntents: intentLists.user.join(','))
      @Analytics.call('get', 'reports/series/intents', params: @Analytics.encoded(params))
    getIntentCounts: (params) =>
      @Analytics.call('get', 'reports/summary/intentCount', params: @Analytics.encoded(params))
    getRetention: (params) =>
      @Analytics.call('get', 'reports/summary/userRetention', params: @Analytics.encoded(params))

  @Handover:
    call: (method, endpoint, {params, body, projectId, token} = {}) =>
      new Promise (resolve, reject) =>
        projectId ||= Globals.projectUuid
        token ||= Globals.projectToken
        if !projectId?
          console.error('No project id set.')
          reject()
        @call(method, "projects/#{projectId}/handover/#{endpoint}", params: params, body: body, token: token)
          .then (response) -> response.json()
          .then (response) -> resolve(response.data)
          .catch(reject)
    getConfig: (project = Globals.project) =>
      return Promise.reject() if !project.token?
      @Handover.call('get', 'config', token: project.token)
    updateConfig: (config, project = Globals.project) =>
      return Promise.reject() if !project.token?
      @Handover.call('post', 'config', body: config, token: project.token)
    getTeams: (project = Globals.project) =>
      return Promise.reject() if !project.token?
      @Handover.call('get', 'teams', projectId: project.uuid, token: project.token)
    createAgentTeam: (team, project = Globals.project) =>
      return Promise.reject() if !project.token?
      @Handover.call('post', 'teams', body: team.export, projectId: project.uuid, token: project.token)
    updateAgentTeam: (team, project = Globals.project) =>
      return Promise.reject() if !project.token?
      @Handover.call('post', "teams/#{team.id}", body: team.export, projectId: project.uuid, token: project.token)
    deleteAgentTeam: (team, project = Globals.project) =>
      return Promise.reject() if !project.token?
      @Handover.call('delete', "teams/#{team.id}", projectId: project.uuid, token: project.token)
    getAgent: (project = Globals.project) =>
      return Promise.reject() if !project.token?
      @Handover.call('get', 'agents/me', projectId: project.uuid, token: project.token)
    getAgents: (project = Globals.project) =>
      return Promise.reject() if !project.token?
      @Handover.call('get', 'agents', projectId: project.uuid, token: project.token)
    saveAgent: (project, agent) =>
      return Promise.reject() if !project.token?
      @Handover.call('post', "agents/#{agent.id}", body: agent, projectId: project.uuid, token: project.token)
    getAnswerTemplates: () =>
      @Handover.call('get', 'templates')
    getAnswerTemplate: (id) =>
      @Handover.call('get', "templates/#{id}")
    updateAnswerTemplate: (template) =>
      @Handover.call('patch', "templates/#{template.id}", body: template.export)
    createAnswerTemplate: (template) =>
      @Handover.call('post', 'templates', body: template.export)
    deleteAnswerTemplate: (template) =>
      @Handover.call('delete', "templates/#{template.id}")
    getAnswerTemplateTags: =>
      @Handover.call('get', 'templates/tags')
    getTags: =>
      @Handover.call('get', 'config/taggingrules')
    saveTag: (tag) =>
      if tag.id?
        @Handover.call('patch', "config/taggingrules/#{tag.id}", body: tag)
      else
        @Handover.call('post', 'config/taggingrules', body: tag)
    deleteTag: (tag) =>
      @Handover.call('delete', "config/taggingrules/#{tag.id}")
    agentManualCheck: (value) =>
      endpoint = if value then 'checkin' else 'checkout'
      @Handover.call('post', "agents/me/#{endpoint}")

  @Inbox:
    call: (method, endpoint, {params, body, projectId, token} = {}) =>
      new Promise (resolve, reject) =>
        projectId ||= Globals.projectUuid
        token ||= Globals.projectToken
        if !projectId?
          console.error('No project id set.')
          reject()
        @call(method, "projects/#{projectId}/inbox/#{endpoint}", params: params, body: body, token: token)
          .then (response) -> response.json()
          .then (response) -> resolve(response.data)
          .catch(reject)
    deleteUser: (conversation) =>
      @Inbox.call('delete', "users/#{conversation.id}/pid")

  @WaTemplates:
    call: (method, endpoint, {channelId, params, body, headers} = {}) =>
      new Promise (resolve, reject) =>
        if !Globals.projectUuid?
          console.error('No project id set.')
          reject()
          return
        @call(method, @WaTemplates.path(endpoint, channelId), params: params, body: body, headers: headers)
          .then (response) -> resolve(response.json())
          .catch(reject)
    path: (endpoint, channelId = null) =>
      if channelId?
        "projects/#{Globals.projectUuid}/channels/whatsapp/#{channelId}/#{endpoint}"
      else
        "projects/#{Globals.projectUuid}/channels/whatsapp/#{endpoint}"

    getJobs: =>
      @WaTemplates.call('get', 'batchmessaging')
        .then (response) -> response.data
    createJob: (channelId) =>
      @WaTemplates.call('post', 'batchmessaging', body: {channelId: channelId})
        .then (response) -> response.data
    deleteJob: (jobId) =>
      @WaTemplates.call('delete', "batchmessaging/#{jobId}")
    setFilters: (jobId, filters) =>
      @WaTemplates.call('patch', "batchmessaging/#{jobId}", body: {config: filters: filters})
    setTemplate: (jobId, template) =>
      @WaTemplates.call(
        'patch',
        "batchmessaging/#{jobId}",
        body: {config: {templateId: template.id, templateName: template.name, templateNamespace: template.namespace}}
      )
    setParams: (jobId, parameters) =>
      @WaTemplates.call('patch', "batchmessaging/#{jobId}", body: {config: parameters: parameters})
    getStats: (jobId) =>
      @WaTemplates.call('get', "batchmessaging/#{jobId}/statistics")
        .then (response) -> response.data
    getAudience: (jobId) =>
      @WaTemplates.call('get', "batchmessaging/#{jobId}/audience")
        .then (response) -> response.data
    sendPreview: (jobId, phoneNumber) =>
      @WaTemplates.call('post', "batchmessaging/#{jobId}/preview", body: {phoneNumber: phoneNumber})
        .then (response) -> response.data
    sendPromotionMessage: (jobId) =>
      @WaTemplates.call('post', "batchmessaging/#{jobId}/send")
    downloadReport: (jobId) =>
      @call('get', @WaTemplates.path("batchmessaging/#{jobId}/report"), headers: {Accept: 'text/csv'})
        .then (response) -> response.text()
    getTemplates: (channelId) =>
      @WaTemplates.call('get', 'proxy/v1/configs/templates', channelId: channelId)
        .then (response) -> response.waba_templates
    createTemplate: (channelId, data) =>
      @WaTemplates.call('post', 'proxy/v1/configs/templates', channelId: channelId, body: data)
    deleteTemplate: (template) =>
      @WaTemplates.call('delete', "proxy/v1/configs/templates/#{template.name}", channelId: template.channelId)
