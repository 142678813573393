
  import KnowledgeSourceBaseUrl from 'models/knowledge_source_base_url'
  import { confirm } from 'helpers'

  import { Button, Input, Label, RadioPanels, Switch } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: KnowledgeSourceBaseUrl

    data: ->
      baseUrl: @modelValue
      exhaustiveSearch: 'on'
      exhaustiveSearchOptions: [
        {key: 'on', label: 'On', description: 'Crawl all subpages first, then filter according to URL patterns. (Might take substantially longer for large pages but finds all relevant subpages.)'}
        {key: 'off', label: 'Off', description: 'Stop crawling when a non-included URL pattern is reached. (Substantially faster, but might miss hidden subpages.)'}
      ]

    created: ->
      @exhaustiveSearch = if @baseUrl.exhaustiveSearch then 'on' else 'off'

    methods:
      update: ->
        @$emit('update:modelValue', @baseUrl)
      updateExhaustiveSearch: ->
        @baseUrl.exhaustiveSearch = if @exhaustiveSearch == 'on' then true else false
        @update()
      addBlackPattern: ->
        @baseUrl.blackPatterns.push('')
      addWhitePattern: ->
        @baseUrl.whitePatterns.push('')
      removeBlackPattern: (index) ->
        @baseUrl.blackPatterns.splice(index, 1)
        @update()
      removeWhitePattern: (index) ->
        @baseUrl.whitePatterns.splice(index, 1)
        @update()

    components:
      Button: Button
      Input: Input
      Label: Label
      RadioPanels: RadioPanels
      Switch: Switch
      XIcon: XIcon
