
  import EmojiPicker from 'vue3-emoji-picker'
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { EmojiHappyIcon } from '@heroicons/vue/outline'

  import'vue3-emoji-picker/dist/style.css'

  export default
    components:
      EmojiHappyIcon: EmojiHappyIcon
      EmojiPicker: EmojiPicker
      Popover: Popover
      PopoverButton: PopoverButton
      PopoverPanel: PopoverPanel
