import PlatformApi from 'models/platform_api'

export default class User
  DEFAULT_TIMEZONE = 'Europe/Berlin'

  @ATTRIBUTES = [
    'firstName'
    'lastName'
    'email'
    'phoneNumber'
    'organizationId'
    'organizationName'
    'organizationChargebeeId'
    'language'
    'permissions'
  ]

  @load: (id) ->
    PlatformApi.Webapp.getUser(id, forModel: true)
      .then (data) => new User(data)

  constructor: (data) ->
    @id = data.id
    @permissions = []
    @update(data)

  update: (data) ->
    for attribute in @constructor.ATTRIBUTES
      @[attribute] = data[attribute] if data[attribute]?
    return this

  reload: ->
    @constructor.load(@id)
      .then (data) => @update(data)

  may: (ptLabel, complement, {stageLevel} = {}) ->
    if stageLevel?
      @permissions.some (p) =>
        p.permission_type == ptLabel &&
          p.complement_id == complement.id &&
          p.stage_levels.includes(stageLevel)
    else
      @permissions.some (p) => p.permission_type == ptLabel && p.complement_id == complement.id

  # select only roles that are at most as potent as the user's role
  selectAssignableRoles: (complement, roles) ->
    roles.filter (role) => @mayAssignRole(role, complement)

  mayAssignRole: (role, complement) ->
    role.permissions.every (permission) =>
      permission.envs.every (env) => @may(permission.type, complement, env)

  permissionComplementIdsFor: (ptLabel) ->
    @permissions
      .filter (p) -> p.permission_type == ptLabel
      .map (p) -> p.complement_id

  Object.defineProperties @prototype,
    label:
      get: ->
        "#{@firstName} #{@lastName}"
